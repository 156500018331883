<template>
  <div class="easily-migrate">
    <div class="easily-migrate-info">
      <div class="easily-migrate-left-part">
        <h3>Shopify</h3>
        <h1>
          Add HelixPay Subscriptions to your Shopify Webstore in the Philippines
        </h1>
        <p>
          We're the only subscription management app for Shopify in the
          Philippines.
        </p>

        <li class="first-list-item">
          <p>Automated recurring payments for subscriptions.</p>
        </li>
        <li class="first-list-item">
          <p>HelixPay team handles the integration for you.</p>
        </li>
        <li class="first-list-item">
          <p>
            Order details update directly to your Shopify OMS. No need to
            monitor two accounts.
          </p>
        </li>
        <li class="first-list-item">
          <p>
            Up and running within a couple days for a full subscription
            experience.
          </p>
        </li>
      </div>
      <div class="easily-migrate-right-part">
        <img
          v-motion
          src="/img/webstore.svg"
          alt=""
          :initial="{
            opacity: 0,
            y: 100,
          }"
          :visibleOnce="{
            opacity: 1,
            y: 0,
            transition: {
              delay: 100,
              stiffness: 200,
              damping: 90,
              mass: 3,
            },
          }"
        />
      </div>
    </div>
  </div>
</template>

<style scoped>
.easily-migrate {
  width: 100%;
  background-color: #f3f7f8;
}

.easily-migrate-info {
  width: 71rem;
  margin: auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4rem;
  align-items: center;
  padding-top: 5rem;
  padding-bottom: 3rem;
  padding-left: 25px;
  border-left: 2px solid white;
}

.easily-migrate-info h3 {
  font-weight: 700;
  font-size: 1.5rem;
  color: #25a4e1;
}

.easily-migrate-info h1 {
  line-height: 1.5;
  font-size: 2rem;
  font-weight: 700;
  margin-top: 1rem;
  margin-left: -27px;
  padding-left: 25px;
  border-left: 2px solid #25a4e1;
}

.easily-migrate-left-part > p {
  margin-top: 1rem;
  font-size: 1rem;
  font-weight: 700;
  opacity: 0.6;
  margin-bottom: 2rem;
  line-height: 2;
}

.easily-migrate-right-part img {
  width: 25rem;
  margin: auto;
  display: block;
}

.easily-migrate-left-part li {
  font-size: 1rem;
  top: -10rem;
  line-height: 2;
  margin-bottom: 20px;
  margin-right: 30px;
  padding: 0px 20px 0px 40px;
  list-style: none;
  background-image: url('/img/check-list.svg');
  background-repeat: no-repeat;
  background-position: 0% 28%;
  background-size: 1rem;
}

.easily-migrate-left-part li p {
  font-weight: 500;
  opacity: 0.6;
}

.easily-migrate-left-part > p {
  line-height: 2;
}

@media only screen and (max-width: 1400px) {
  .easily-migrate-info {
    width: 60rem;
    margin: auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}

@media only screen and (max-width: 1050px) {
  .easily-migrate-info {
    width: 40rem;
    margin: auto;
    display: grid;
    grid-template-columns: 1fr;
  }

  .easily-migrate-right-part img {
    width: 30rem;
    margin: auto;
    display: block;
  }
}

@media only screen and (max-width: 650px) {
  .easily-migrate-info {
    width: 90%;
    margin: auto;
    display: grid;
    grid-template-columns: 1fr;
    border-left: none;
  }

  .easily-migrate-left-part h3 {
    font-size: 1rem;
  }

  .easily-migrate-left-part h1 {
    font-size: 1.5rem;
    border-left: none;
  }

  .easily-migrate-left-part p {
    font-size: 1rem;
    border-left: none;
  }

  .easily-migrate-right-part img {
    width: 90%;
  }

  .easily-migrate-info {
    padding-left: 0;
  }
}
</style>
