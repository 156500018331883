<template>
  <div class="integrations-main">
    <div class="integrations">
      <img
        v-motion
        src="/img/integrations-img2-min.png"
        class="integ-mobile"
        alt=""
        :initial="{
          opacity: 0,
          y: 100,
        }"
        :visibleOnce="{
          opacity: 1,
          y: 0,
          transition: {
            delay: 100,
            stiffness: 200,
            damping: 90,
            mass: 3,
          },
        }"
      />

      <h4>Integrations</h4>
      <h1>Flexible options for recurring subscriptions</h1>
      <p class="integrations-para">
        Select option that is the best fit for your business needs. HelixPay
        Checkout is custom built for subscriptions and recurring payments.
      </p>

      <div class="integrations-info">
        <img
          v-motion
          src="/img/integrations-img1-min.png"
          class="integ-original forWeb"
          alt=""
          :initial="{
            opacity: 0,
            y: 100,
          }"
          :visibleOnce="{
            opacity: 1,
            y: 0,
            transition: {
              delay: 100,
              stiffness: 200,
              damping: 90,
              mass: 3,
            },
          }"
        />
      </div>
    </div>
  </div>
</template>

<style scoped>
.intergrations-main {
  width: 100%;
}

.integrations {
  width: 71rem;
  margin: auto;
  padding-top: 2rem;
  border-left: 2px solid white;
}

.integrations h4 {
  font-weight: 700;
  font-size: 1.5rem;
  padding-top: 1rem;
  padding-left: 30px;
  padding-right: 20px;
  color: #25a4e1;
}

.integrations h1 {
  line-height: 1.5;
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 1.5rem;
  margin-top: 1rem;
  margin-left: -2px;
  padding-left: 30px;
  padding-right: 20px;
  border-left: 2px solid #25a4e1;
}

.integrations-para {
  width: 70%;
  font-size: 1rem;
  line-height: 2;
  padding-left: 30px;
  font-weight: 700;
  opacity: 0.5;
}

.integrations-info {
  display: grid;
  grid-template-columns: 1fr;
  margin: auto;
  margin-top: 3.5rem;
  padding-bottom: 4rem;
  align-items: center;
  width: 80%;
}

.integ-original {
  width: 100%;
  height: 100%;
  margin: auto;
}

.integ-mobile {
  width: 100%;
  height: 100%;
  margin: auto;
  display: none;
}

@media only screen and (max-width: 1400px) {
  .integrations {
    width: 60rem;
  }
}

@media only screen and (max-width: 1050px) {
  .forMobile,
  .integ-mobile {
    display: block;
  }

  .forWeb {
    display: none;
  }

  .integrations h4 {
    margin-top: 2rem;
  }

  .integrations {
    width: 40rem;
  }

  .integ-original {
    display: none;
  }

  .integ-mobile {
    width: 90%;
    height: auto;
    margin: auto;
    display: block;
  }
}

@media only screen and (max-width: 650px) {
  .integrations {
    width: 90%;
    border-left: none;
  }

  .integrations h4 {
    font-weight: 700;
    font-size: 1rem;
    padding-left: 0;
    margin-left: 0;
  }

  .integrations h1 {
    font-size: 1.5rem;
    font-weight: 700;
    margin-left: 0;
    padding-left: 0;
    border-left: none;
  }

  .integrations-para {
    width: 100%;
    padding-left: 0;
  }

  .integrations-info {
    width: 100%;
  }

  .integrations-info img {
    width: 100%;
  }
}
</style>
