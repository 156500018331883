<template>
  <div class="integrate-main">
    <div class="integrate-subtitle">
      <h1>
        Integrate via API for a fully customized experience with your existing
        system
      </h1>

      <h3>
        Don't spend engineering time rebuilding recurring payments UX. We've got
        you covered.
      </h3>
    </div>

    <div class="integrate-info">
      <div class="integrate-left-info">
        <h2>Full integration with our API integration.</h2>
        <p>
          Clear API documentation with complete customization for any type of
          recurring payment system.
        </p>
        <p>
          Check out our API docs
          <a
            href="https://helixpay.readme.io/reference/introduction"
            style="color: #25a4e1; text-decoration: none; font-weight: 900"
          >
            here.
          </a>
        </p>

        <div class="integrate-info-ul">
          <li class="first-list-item">
            <p>
              Integrate HelixPay Subscription Checkout directly into your
              website.
            </p>
          </li>
          <li class="first-list-item">
            <p>
              Set the subscription billing details via API and HelixPay handles
              the recurring payments.
            </p>
          </li>
          <li class="first-list-item">
            <p>HelixPay CheckOut with all popular payment methods.</p>
          </li>
          <li class="first-list-item">
            <p>
              Use HelixPay business tools & analytics or pull status updates
              into your existing dashboards with our webhooks.
            </p>
          </li>
        </div>
      </div>

      <div class="integrate-right-info">
        <img
          v-motion
          src="/img/saas2.png"
          alt=""
          :initial="{
            opacity: 0,
            y: 100,
          }"
          :visibleOnce="{
            opacity: 1,
            y: 0,
            transition: {
              delay: 100,
              stiffness: 200,
              damping: 90,
              mass: 3,
            },
          }"
        />
      </div>
    </div>
  </div>
</template>

<style scoped>
.integrate-main {
  width: 100%;
  line-height: 1.5;
  background-color: black;
  margin-bottom: 0.1rem;
}

.integrate-subtitle {
  color: white;
  width: 71rem;
  margin: auto;
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.integrate-subtitle h1 {
  width: 80%;
  color: #25a4e1;
  line-height: 1.5;
  font-weight: 700;
  font-size: 2rem;
}

.integrate-subtitle h3 {
  width: 70%;
  color: #9affe1;
  font-weight: 700;
  line-height: 2;
  font-size: 1.3rem;
}

.integrate-info {
  width: 71rem;
  margin: auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  padding-bottom: 2rem;
}

.integrate-info h2 {
  font-weight: 700;
  font-size: 1.5rem;
  color: #25a4e1;
}

.integrate-left-info p {
  color: white;
  font-weight: 500;
  margin-top: 1rem;
  line-height: 2;
}

.integrate-info h4 {
  margin-top: 2rem;
  color: white;
}

.integrate-info h4:nth-child(3) {
  margin-top: 1rem;
}

.first-list-item {
  list-style-type: disc;
  list-style-position: outside;
  padding: 20px 0 10px 20px;
}

.integrate-info-ul li:nth-child(1) {
  margin-top: 3.5rem;
}

.integrate-info-ul li {
  line-height: 2;
  font-size: 1rem;
  margin-bottom: 20px;
  margin-right: 30px;
  padding: 0px 20px 0px 40px;
  list-style: none;
  background-image: url('../assets/images/check-list.svg');
  background-repeat: no-repeat;
  background-position: 0% 13%;
  background-size: 1rem;
}

.integrate-info-ul li p {
  line-height: 2;
  font-weight: 500;
  opacity: 0.6;
}

.integrate-info img {
  width: 80%;
  display: block;
  margin: auto;
  margin-top: 2rem;
  margin-bottom: 3rem;
}

@media only screen and (max-width: 1400px) {
  .integrate-subtitle,
  .integrate-info {
    width: 60rem;
  }

  .integrate-info {
    padding-bottom: 3rem;
  }
}

@media only screen and (max-width: 1050px) {
  .integrate-subtitle,
  .integrate-info {
    width: 40rem;
  }

  .integrate-subtitle h1,
  .integrate-subtitle h3 {
    width: 100%;
  }

  .integrate-info {
    grid-template-columns: 1fr;
  }

  .integrate-info img {
    width: 80%;
  }

  .right-part img {
    height: inherit;
    width: 11rem;
    display: grid;
    grid-template-rows: 1fr;
    margin-left: auto;
    margin-right: auto;
  }
}

@media only screen and (max-width: 650px) {
  .integrate-subtitle,
  .integrate-info {
    width: 90%;
  }

  .integrate-subtitle h1 {
    font-size: 1.5rem;
  }

  .integrate-subtitle h3 {
    font-size: 1rem;
  }

  .right-part img {
    height: inherit;
    width: 8.5rem;
    margin: auto;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .integrate-info-ul li {
    font-size: 1rem;
    background-size: 3vmin;
  }

  .integrate-right-info {
    width: 100%;
  }

  .integrate-right-info img {
    width: 90%;
  }
}
</style>
